import React, { useState, useEffect } from 'react'
import { Input, Button, Spin } from 'antd'
import './App.css'

function Panda() {
  return (
    <div>
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" fill="#3F3D56" />
        <circle id="leftEye" cx="30" cy="30" r="10" fill="#FFFFFF" />
        <circle id="rightEye" cx="70" cy="30" r="10" fill="#FFFFFF" />
        <path d="M50,60 C50,65 45,70 40,70 C35,70 30,65 30,60" fill="#FFFFFF" />
        <path d="M70,60 C70,65 75,70 80,70 C85,70 90,65 90,60" fill="#FFFFFF" />
        <circle cx="30" cy="30" r="5" fill="#3F3D56" />
        <circle cx="70" cy="30" r="5" fill="#3F3D56" />
        <path d="M53,50 C53,45 50,40 47,40 C44,40 41,45 41,50" fill="#3F3D56" />
      </svg>
    </div>
  )
}

function App() {
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [encouragement, setEncouragement] = useState('')

  useEffect(() => {
    async function fetchEncouragement() {
      setIsLoading(true)

      try {
        const response = await fetch(
          `api/encourage?guide=${query}`
        )
        const data = await response.json()
        console.log(data)
        if (data.error) {
          throw new Error(data.reason)
        }
        setEncouragement(data.completion.choices[0].text)
      } catch (error) {
        setEncouragement(error.message)
      } finally {
        setIsLoading(false)
        setIsSubmitting(false)
      }
    }

    if (query && isSubmitting) {
      fetchEncouragement()
    }
  }, [query, isSubmitting])

  function handleQueryChange(event) {
    setQuery(event.target.value)
  }

  function handleEncourageClick() {
    if (query) {
      setEncouragement('')
      setIsSubmitting(true)
    }
  }

  function handleBackClick() {
    setEncouragement('')
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: encouragement ? 'default' : '100vh',
      }}
    >
      {encouragement ? (
        <div className='container'>
          <em>
            {encouragement.split('\n\n').map(part => (
              <p>{part}</p>
            ))}
          </em>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleBackClick}>Back</Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  marginBottom: '20px',
                  justifyContent: 'center',
                }}
              >
                <div style={{ width: '130px' }}>
                  <Panda />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <Input
                  placeholder="Who's your hero?"
                  size="large"
                  value={query}
                  onChange={handleQueryChange}
                  onPressEnter={handleEncourageClick}
                />
                <Button
                  size="large"
                  onClick={handleEncourageClick}
                  className="lfg"
                >
                  LFG!
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default App
